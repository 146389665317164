import { cloneDeep } from "lodash";
import sanitizeHtml from "sanitize-html";
import { imgSrcUrlHasError, aHrefUrlHasError } from "./bandicoot-url-validator";

export const allowedTags = [
  "div",
  "span",
  "p",
  "ul",
  "ol",
  "li",
  "font",
  "blockquote",
  "b",
  "strong",
  "i",
  "em",
  "u",
  "a",
  "br",
  "img",
];

export const allowedDataTextAsImageValues = [
  "First or Business Name",
  "Last Name",
  "Primary Phone Number",
  "Primary Contact First Name or Business Name",
  "Primary Contact Last Name",
];

export const allowedSelfClosingTags = ["br", "img"];

export const allowedAttributesByTag = {
  a: ["href"],
  img: ["src", "style", { name: "data-size", values: ["original", "small", "medium", "large"] }],
  font: ["style", { name: "data-integer-size", values: ["1", "2", "3", "4", "5", "6", "7"] }],
  div: ["style"],
  blockquote: ["style"],
  span: [
    "style",
    {
      name: "data-text-as-image",
      values: allowedDataTextAsImageValues,
    },
  ],
  p: ["style"],
  ol: ["style"],
  ul: ["style"],
};

export const allowedStylesByTag = {
  img: { "max-width": ["96px", "200px", "420px", "500px"], "max-height": ["50px", "100px", "150px", "200px"] },
  font: { "font-size": ["10px", "12px", "14px", "16px", "18px", "24px", "30px"] },
  div: { "text-align": ["left", "center", "right"] },
  blockquote: { margin: ["0 0 0 40px"], border: ["none"], padding: ["0px"] },
  p: { margin: ["0"] },
  ol: { padding: ["0 0 0 40px"] },
  ul: { padding: ["0 0 0 40px"] },
  span: { "font-size": ["10px", "12px", "14px", "16px", "18px", "24px", "30px"] },
};

export const allowedClassesByTag = {
  span: ["cp-merge-tag"],
  div: ["signature"],
};

export const allowedSchemesByTag = {
  a: ["https", "http", "mailto"],
  img: ["https"],
};

export const imgSrcUrlValues = {
  allowedProtocols: ["https"],
  allowedHostnames: [
    "cdn.canopytax.com",
    "cdn-stage.canopy.ninja",
    "cdn-integ.canopy.ninja",
    "canopy-staging.s3-us-west-2.amazonaws.com",
    "beanstalk-production.s3-us-west-2.amazonaws.com",
  ],
  restrictedStrings: ["data:", "text/html", "base64", "image/svg+xml"],
};

export const aHrefUrlValues = {
  allowedProtocols: ["https", "http", "mailto"],
  restrictedStrings: ["data:", "text/html", "base64"],
  hostnameRestrictedStrings: ["%", ":", "@"],
};

const sanitizeHtmlConfig: sanitizeHtml.IOptions = {
  allowedTags,
  selfClosing: allowedSelfClosingTags,
  allowedAttributes: allowedAttributesByTag,
  allowedClasses: allowedClassesByTag,
  allowedStyles: Object.fromEntries(
    Object.entries(allowedStylesByTag).map(([tag, styles]) => [
      tag,
      Object.fromEntries(
        Object.entries(styles).map(([prop, values]) => [prop, values.map((v) => new RegExp(`^${v}$`))])
      ),
    ])
  ),
  transformTags: {},
  allowedSchemesByTag,
  allowedSchemesAppliedToAttributes: ["href", "src"],
  allowProtocolRelative: false,
  exclusiveFilter: ({ tag, attribs }) => {
    if (tag === "img") return imgSrcUrlHasError(attribs.src);
    if (tag === "a") return aHrefUrlHasError(attribs.href);
    return false;
  },
};

export function getSanitizeRichTextHtmlConfig() {
  return cloneDeep(sanitizeHtmlConfig);
}
