import { cloneDeep } from "lodash";
import { allowedTags, allowedDataTextAsImageValues } from "./sanitize-rich-text-html.config";

const domPurifyConfig = {
  ALLOWED_TAGS: ["html", "body", "#text", ...allowedTags],
  ADD_ATTR: ["href"], // Note that we use two sanitizers for some reason, so this attribute is whitelisted for specific elements based on the sanitize-html config
  KEEP_CONTENT: false,
};

export default function getDomPurifyConfig() {
  return cloneDeep(domPurifyConfig);
}

export function transformHtml(node) {
  // This hook is used to transform element tags and their attributes as needed for bandicoot.
  node = transformCpMergeTagToDataTextAsImage(node);
  node = transformAlignAttributeToStyleTextAlignAttribute(node);
  node = transformHrefToRemoveWhiteSpaces(node);

  return node;
}

export function removeHtmlAttributeStubs(node) {
  // This hook is used to remove attribute stubs (attributes with no value).
  // Example: <span data-text-as-image></span> would become <span></span> since data-text-as-image doesn't have a value.
  node = removeAttributeStubs(node);

  return node;
}

function transformCpMergeTagToDataTextAsImage(node) {
  // add a 'data-text-as-image' attribute if the 'cp-merge-tag' class exists
  if (node.classList && node.classList.contains("cp-merge-tag")) {
    if (node.textContent && allowedDataTextAsImageValues.includes(node.textContent)) {
      if (node.setAttribute && typeof node.setAttribute === "function") {
        node.setAttribute("data-text-as-image", node.textContent);
      }
    }
  }

  return node;
}

function transformAlignAttributeToStyleTextAlignAttribute(node) {
  // transform 'align' attributes to 'text-align' style attributes
  if (node.getAttribute) {
    const alignAttribute = node.getAttribute("align");
    if (alignAttribute) {
      if (alignAttribute === "left" || alignAttribute === "center" || alignAttribute === "right") {
        node.style["text-align"] = alignAttribute;
      }

      node.removeAttribute("align");
    }
  }

  return node;
}

function transformHrefToRemoveWhiteSpaces(node) {
  // transform href attributes to remove white space characters
  if (node.getAttribute) {
    const hrefAttribute = node.getAttribute("href");
    if (hrefAttribute) {
      node.setAttribute("href", hrefAttribute.replace(/\s/g, ``));
    }
  }

  return node;
}

function removeAttributeStubs(node) {
  // remove attribute stubs
  if (node.getAttributeNames) {
    const attributeNames = node.getAttributeNames();
    attributeNames.forEach((attributeName) => {
      const attributeValue = node.getAttribute(attributeName);
      if (!attributeValue) {
        node.removeAttribute(attributeName);
      }
    });
  }

  return node;
}
