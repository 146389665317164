import { imgSrcUrlValues, aHrefUrlValues } from "./sanitize-rich-text-html.config";
import { getCommonWebProtocols, getCommonEmailProtocols } from "./bandicoot-url-validator.helper";

export const urlErrorTypes = {
  INVALID_URL: `invalid URL`,
  UNKNOWN_URL_PROTOCOL: `unknown URL protocol`,
  RESTRICTED_URL_PROTOCOL: `restricted URL protocol`,
  RESTRICTED_URL_HOSTNAME: `restricted URL hostname`,
  RESTRICTED_URL_STRING: `restricted URL string`,
  NON_HUMAN_READABLE_URL: `non-human readable URL`,
  RESTRICTED_URL_HOSTNAME_STRING: `restricted URL hostname string`,
  INVALID_URL_HOSTNAME_FORMAT: `invalid URL hostname format`,
  INVALID_EMAIL_FORMAT: `invalid email format`,
  INVALID_EMAIL_LOCAL: `invalid email local`,
  INVALID_EMAIL_DOMAIN: `invalid email domain`,
  UNEXPECTED_URL_ERROR: `unexpected URL error`,
};

const chainableValidationFunctions = {
  urlAllowedProtocolsCheck,
  urlPartsCheck,
  urlAllowedHostnamesCheck,
  urlRestrictedStringsCheck,
  urlHumanReadabilityCheck,
  urlHostnameRestrictedStringsCheck,
  urlHostnameEndsWithLetterCheck,
  emailMatchesGeneralFormatCheck,
  emailGenerateParts,
  emailLocalPartCheck,
  emailDomainCheck,
};

const commonWebProtocols = getCommonWebProtocols();
const commonEmailProtocols = getCommonEmailProtocols();

export function imgSrcUrlHasError(url) {
  return !!validateImgSrcUrl(url).error;
}

export function aHrefUrlHasError(url) {
  return !!validateAHrefUrl(url).error;
}

export function validateImgSrcUrl(url) {
  return startUrlChecks(url)
    .urlAllowedProtocolsCheck(imgSrcUrlValues.allowedProtocols)
    .urlRestrictedStringsCheck(imgSrcUrlValues.restrictedStrings)
    .urlAllowedHostnamesCheck(imgSrcUrlValues.allowedHostnames);
}

export function validateAHrefUrl(url) {
  let validationObj = startUrlChecks(url)
    .urlAllowedProtocolsCheck(aHrefUrlValues.allowedProtocols)
    .urlRestrictedStringsCheck(aHrefUrlValues.restrictedStrings);

  if (!validationObj.error) {
    if (commonWebProtocols.includes(validationObj.url.protocol.replace(":", ""))) {
      return validateAHrefUrlForWeb(validationObj);
    } else if (commonEmailProtocols.includes(validationObj.url.protocol.replace(":", ""))) {
      return validateAHrefUrlForEmail(validationObj);
    } else {
      validationObj.error = urlErrorTypes.UNKNOWN_URL_PROTOCOL;
    }
  }

  return validationObj;
}

function validateAHrefUrlForWeb(validationObj) {
  return validationObj
    .urlHumanReadabilityCheck()
    .urlHostnameRestrictedStringsCheck(aHrefUrlValues.hostnameRestrictedStrings)
    .urlHostnameEndsWithLetterCheck()
    .urlPartsCheck();
}

function validateAHrefUrlForEmail(validationObj) {
  return validationObj.emailMatchesGeneralFormatCheck().emailGenerateParts().emailLocalPartCheck().emailDomainCheck();
}

function startUrlChecks(originalUrl) {
  let error = null;
  let rawUrl = originalUrl;
  let url = originalUrl;

  if (!originalUrl) {
    error = urlErrorTypes.INVALID_URL;
  }

  try {
    url = new URL(rawUrl); // turn into a URL object
  } catch {
    try {
      // if no protocol is provided, assume http:
      url = new URL(`http://${rawUrl}`);
      rawUrl = `http://${rawUrl}`;
    } catch {
      error = urlErrorTypes.INVALID_URL;
    }
  }

  try {
    // Microsoft Edge does not enforce that 'href' and 'pathname' always be a string.
    // In fact, even though Edge may successfully create a 'new URL()',
    // it will sometimes turn the 'href' or 'pathname' property into an error type object.
    // Trying to access the properties triggers the 'catch' if they are in this state.
    if (!error) {
      if (typeof url !== "string") {
        for (const prop in url) {
          url[prop];
        }
      }
    }
  } catch {
    error = urlErrorTypes.UNEXPECTED_URL_ERROR;
  }

  const validationObj = Object.create(chainableValidationFunctions);
  validationObj.url = url;
  validationObj.rawUrl = rawUrl;
  validationObj.originalUrl = originalUrl;
  validationObj.error = error;

  return validationObj;
}

function urlPartsCheck() {
  if (!this.error) {
    // generate hostname top-level domain
    const hostnameDotArray = this.url.hostname.split(`.`);
    if (hostnameDotArray[0] === "www" && hostnameDotArray.length < 3) {
      this.error = urlErrorTypes.INVALID_URL_HOSTNAME_FORMAT;
    } else if (hostnameDotArray.length < 2) {
      this.error = urlErrorTypes.INVALID_URL_HOSTNAME_FORMAT;
    } else {
      this.url.urlHostnameTopLevelDomain = hostnameDotArray.pop();
    }
  }

  return this;
}

function urlAllowedProtocolsCheck(allowedProtocols = []) {
  if (!this.error) {
    const checkProtocols = allowedProtocols.map((protocol) => `${protocol}:`);
    if (!checkProtocols.includes(this.url.protocol)) {
      this.error = urlErrorTypes.RESTRICTED_URL_PROTOCOL;
    }
  }

  return this;
}

function urlAllowedHostnamesCheck(allowedHostnames = []) {
  if (!this.error) {
    if (!allowedHostnames.includes(this.url.hostname)) {
      this.error = urlErrorTypes.RESTRICTED_URL_HOSTNAME;
    }
  }

  return this;
}

function urlRestrictedStringsCheck(restrictedStrings = []) {
  if (!this.error) {
    if (restrictedStrings.some((restrictedString) => this.url.href.includes(restrictedString))) {
      this.error = urlErrorTypes.RESTRICTED_URL_STRING;
    }
  }

  return this;
}

function urlHumanReadabilityCheck() {
  if (!this.error) {
    //
    if (/^[\d\.xX]+$/.test(this.url.hostname)) {
      this.error = urlErrorTypes.NON_HUMAN_READABLE_URL;
    }
  }

  return this;
}

function urlHostnameRestrictedStringsCheck(restrictedStrings = []) {
  if (!this.error) {
    // `new URL()` function will turn a URL encoded url into a readible form.  We still want to prevent this from happening so we do some additional checks against the rawUrl.
    const rawUrlDoubleSlashSplitArray = this.rawUrl.split(`//`);
    if (rawUrlDoubleSlashSplitArray.length === 2) {
      const rawUrlWithoutProtocol = rawUrlDoubleSlashSplitArray[1];
      const rawUrlHostname = rawUrlWithoutProtocol.split("/")[0];
      if (restrictedStrings.some((restrictedString) => rawUrlHostname.includes(restrictedString))) {
        this.error = urlErrorTypes.RESTRICTED_URL_HOSTNAME_STRING;
      }
    } else {
      this.error = urlErrorTypes.RESTRICTED_URL_HOSTNAME_STRING;
    }

    if (!this.error) {
      // url hostname must not contain any of the restricted strings
      if (restrictedStrings.some((restrictedString) => this.url.hostname.includes(restrictedString))) {
        this.error = urlErrorTypes.RESTRICTED_URL_HOSTNAME_STRING;
      }
    }
  }

  return this;
}

function urlHostnameEndsWithLetterCheck() {
  if (!this.error) {
    // url hostname must end with a letter
    if (/^.*[^a-zA-z]$/.test(this.url.hostname)) {
      this.error = urlErrorTypes.INVALID_URL_HOSTNAME_FORMAT;
    }
  }

  return this;
}

function emailMatchesGeneralFormatCheck() {
  if (!this.error) {
    // See https://en.wikipedia.org/wiki/Email_address
    if (
      !/^[a-zA-Z0-9\!\#\$\%\&\'\*\+\-\/\=\?\^\_\`\{\|\}\~\.]+@[a-zA-Z0-9\-]+\.[a-zA-Z]+$/.test(this.url.pathname) || // address should match general email format
      this.url.pathname.includes(`..`) // address cannot have consecutive .'s
    ) {
      this.error = urlErrorTypes.INVALID_EMAIL_FORMAT;
    }
  }

  return this;
}

function emailGenerateParts() {
  if (!this.error) {
    // generate email address local, domain, top-level domain, and mail server parts
    this.url.emailLocalPart = this.url.pathname.split(`@`)[0];
    this.url.emailDomain = this.url.pathname.split(`@`)[1];
    const domainDotArray = this.url.emailDomain.split(`.`);
    this.url.emailTopLevelDomain = domainDotArray.pop();
    this.url.emailMailServer = domainDotArray.join(`.`);
  }

  return this;
}

function emailLocalPartCheck() {
  if (!this.error) {
    // See https://en.wikipedia.org/wiki/Email_address
    if (
      this.url.emailLocalPart.length > 64 || // local part cannot be greater than 64 characters
      this.url.emailLocalPart[0] === `.` || // local part cannot start with . character
      this.url.emailLocalPart[this.url.emailLocalPart.length - 1] === `.` // local part cannot end with . character
    ) {
      this.error = urlErrorTypes.INVALID_EMAIL_LOCAL;
    }
  }

  return this;
}

function emailDomainCheck() {
  if (!this.error) {
    // See https://en.wikipedia.org/wiki/Email_address
    if (
      this.url.emailDomain.length > 255 || // domain cannot be greater than 255 characters
      /^\d+$/.test(this.url.emailTopLevelDomain) || // top-level domain cannot be all numbers
      this.url.emailMailServer[0] === `-` || // mail server cannot start with a - character
      this.url.emailMailServer[this.url.emailMailServer.length - 1] === `-` // mail server cannot end with a - character
    ) {
      this.error = urlErrorTypes.INVALID_EMAIL_DOMAIN;
    }
  }

  return this;
}
