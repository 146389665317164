import sanitizeHtml from "sanitize-html";
import DOMPurify from "dompurify";
import { getSanitizeRichTextHtmlConfig } from "./sanitize-rich-text-html.config";
import getDomPurifyConfig, { transformHtml, removeHtmlAttributeStubs } from "./dom-purify-bandicoot.config";

const sanitizeHtmlConfig = getSanitizeRichTextHtmlConfig();
const domPurifyConfig = getDomPurifyConfig();

const sanitizedHtmlAcceptanceConfig = {
  sanitizedHtmlMustStartAndEndWithAngleBrackets: false,
  sanitizedHtmlMustNotContainAngleBrackets: false,
};

export function sanitizeBandicootHtml(suspectHtml) {
  const config = { ...sanitizedHtmlAcceptanceConfig };
  return sanitizeRichTextHtml(suspectHtml, config);
}

export function sanitizeRichTextHtml(suspectHtml, config = {}) {
  const acceptanceConfig = { ...sanitizedHtmlAcceptanceConfig, ...config };

  const sanitizedHtml = recursiveSanitizeRichTextHtml(suspectHtml);

  // After sanitization, no html modifications should occur.
  // The shouldAcceptSanitizedHtml function only allows for some final checks on whether or not the sanitized html should be accepted or not.
  // If the sanitized html is not accepted, an empty string is returned.
  return shouldAcceptSanitizedHtml(sanitizedHtml, acceptanceConfig) ? sanitizedHtml : ``;
}

export default sanitizeRichTextHtml;

function recursiveSanitizeRichTextHtml(suspectHtml, recursiveSanitizationCount = 0) {
  const sanitizedHtml = sanitizeSuspectHtml(suspectHtml);

  // The below recursive sanitization check is to ensure the sanitization process is stable.
  // This ensures that the sanitized html itself hasn't unintentionally composed suspect html during sanitization.
  // There is a performance downside to this (as html may be sanitized multiple times), but it is safety check.
  if (recursiveSanitizationCount > 5) {
    // The sanitized html is taking more times to stabilize than expected, so return an empty string.
    // The max recursiveSanitizationCount number of 5 is arbitrary, but in theory it should usually only take 2 iterations at most:
    //   1 iteration if the original suspectHtml already appears okay.
    //   2 iterations if the original suspectHtml needs some sanitization.
    //   3+ iterations means the sanitized html itself needs additional sanitization.
    return ``;
  } else if (sanitizedHtml === suspectHtml) {
    // The sanitized html appears stable, so return the sanitized html.
    return sanitizedHtml;
  } else {
    // The sanitized html is not yet stable, so keep sanitizing the html.
    return recursiveSanitizeRichTextHtml(sanitizedHtml, ++recursiveSanitizationCount);
  }
}

function sanitizeSuspectHtml(suspectHtml) {
  // The below double performHtmlSanitization is due to the sanitization process reversing (mirroring) element attribute sequence during each pass.
  // In order to check if the sanitization process is stable, we need the element attribute sequence to be the same, so we do it twice (to re-reverse).
  const reversedAttributeOrderSanitizedHtml = performHtmlSanitization(suspectHtml);
  return performHtmlSanitization(reversedAttributeOrderSanitizedHtml);
}

function performHtmlSanitization(suspectHtml) {
  // the transformPassHtml is primarily done for html transformation purposes
  DOMPurify.addHook("afterSanitizeElements", transformHtml);
  const transformPassHtml = DOMPurify.sanitize(suspectHtml, domPurifyConfig);
  DOMPurify.removeHook("afterSanitizeElements", transformHtml);

  // the sanitizePassHtml is primarily done for sanitization purposes
  const sanitizePassHtml = sanitizeHtml(transformPassHtml, sanitizeHtmlConfig);

  // the removeAttributeStubPassHtml is primarily done for removing attribute stubs
  DOMPurify.addHook("afterSanitizeElements", removeHtmlAttributeStubs);
  const removeAttributeStubPassHtml = DOMPurify.sanitize(sanitizePassHtml, domPurifyConfig);
  DOMPurify.removeHook("afterSanitizeElements", removeHtmlAttributeStubs);

  return removeAttributeStubPassHtml;
}

function shouldAcceptSanitizedHtml(sanitizedHtml, config) {
  if (config.sanitizedHtmlMustStartAndEndWithAngleBrackets && !/^<.+>$/s.test(sanitizedHtml)) {
    return false;
  }

  if (config.sanitizedHtmlMustNotContainAngleBrackets && /[<>]/.test(sanitizedHtml)) {
    return false;
  }

  return true;
}
